<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Information" style="width:100vw">
  <div id="e20a993e">
    文章资讯
  </div>
  <div class="ae22fdbf">
      <el-select id="acad047c" placeholder="请选择资讯类别" v-model="val_acad047c">
          <el-option v-for="item in op_acad047c" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
      </el-select>
      <el-button  @click="clicka6357e()">
        确定
      </el-button>
      <div id="a6357c9e">
       <el-button  @click="clicka6357c9e()">
        +添加资讯
      </el-button>
      </div>

  </div>

  <el-table id="c7553564" :data="tbd_c7553564" stripe="" border="">
    <el-table-column prop="e2009ea0" width="50px" label="序号" align="center">
    </el-table-column>
    <el-table-column prop="articleid" width="50px" label="文章ID" align="center">
    </el-table-column>
    <el-table-column prop="a62a90fe" label="标题" align="center">
    </el-table-column>
    <el-table-column prop="a5d19e76" label="发布日期" align="center">
    </el-table-column>
    <el-table-column prop="c331accc" width="120px" label="状态" align="center">
    </el-table-column>
    <el-table-column prop="e54101c0"  label="类别" align="center">
    </el-table-column>
    <el-table-column prop="aea45ddb" label="推荐到首页" width="100px">
      <template slot-scope="scope" align="center">
            <el-switch
              v-model="scope.row.aea45ddb"
              active-color="#13ce66"
              inactive-color="#999"
              @change="RecommandClick(scope.row)"
            >
            </el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="fadfeca6" label="置顶" width="100px" align="center">
      <template slot-scope="scope">
            <el-switch
              v-model="scope.row.fadfeca6"
              active-color="#13ce66"
              inactive-color="#999"
              @change="ArticleTopClick(scope.row)"
            >
            </el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="move" label="移动" width="100px" align="center">
      <template slot-scope="scope">
        <el-button @click="moveUp(scope.$index)" type="text" size="small">上移</el-button>
        <el-button @click="moveDown(scope.$index)" type="text" size="small">下移</el-button>
      </template>
    </el-table-column>
    <el-table-column prop="a13462e8" label="操作" width="300px" align="center">
      <template slot-scope="scope">
        <el-button
              type="info"
              plain
              @click="ArticleStop(scope.row)"
              size="small"
              >{{ scope.row.unStatus }}</el-button>
        <el-button
            @click="articleDeleteClick(scope.row)"
            type="danger"
            size="small"
        >删除</el-button
        >
        <el-button
            @click="articleUpdateClick(scope.row)"
            type="success"
            size="small"
        >编辑</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
     id="ac56dd61"
     class="f6be8bc2"
     :page-size="pageSize"
     layout="prev, pager, next, jumper"
     :total="pageTotalNum"
     :current-page.sync="currentPage"
     @current-change="choosePage">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_delArticle,
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList,
  DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle,
  DALINEWEB_ARTICLECENTER_V0X0X1_exchangeOrderID
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_c7553564:[],
      //在此注释下方添加自定义变量
      pageSize: 15,
      pageTotalNum: 0,
      e2009ea0: "",
      a62a90fe: "",
      a5d19e76: "",
      c331accc: [
        {value: 0,label: '使用'},
        {value: 2,label: '停用'}
      ],
      e54101c0: "",
      aea45ddb: "",
      fadfeca6: "",
      val_acad047c:"",
      currentPage:1,
      op_acad047c:[
        {label:"私域流量建设",value:167},
        {label:"私域流量建设(解决方案)",value:168},
        {label:"数字资产化",value:169},
        {label:"数字资产化(解决方案)",value:170},
        {label:"一体化业务开发平台",value:41},
        {label:"关于我们",value:87},
        {label:"新闻中心",value:-2},
        {label:"企业简介",value:1},
        {label:"公司招聘",value:2},
        {label:"产品案例",value:171}
      ]
    }
  },
  mounted(){
    this.refreshPage()
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicka6357c9e(){
      this.$router.push('NewInformation')
    },
    //在此注释下方添加自定义函数或事件
    async choosePage(val) {
      this.refreshPage(val);
    },
    async freshData(res, recordStart = 1) {
      const articleListData = JSON.parse(JSON.stringify(res.retContent));
      const articleList = JSON.parse(articleListData.retContent)
      // console.log('articleList', articleList)
      let num = recordStart;
      this.pageTotalNum = articleList.totalNum;
      this.tbd_c7553564 = [];
      articleList.articleList.forEach((element) => {
        let tmp = element;
        tmp.num = num;
        tmp.e2009ea0 = num;
        num += 1;
        tmp.a62a90fe = element.articleTitle;
        tmp.a5d19e76 = element.insertTime;
        tmp.articleid = element.articleID
        tmp.c331accc = element.status === 0 ? "使用" : "停用";
        tmp.unStatus = element.status === 0 ? "停用" : "使用";
        tmp.aea45ddb = element.preview === 1 ? true : false;
        tmp.unRecommand = element.preview === 0 ? false : true;
        tmp.fadfeca6 = element.top === 1 ? true : false;
        tmp.unRecommand = element.top === 0 ? false : true;
        let articleTypeNote = {
          '167': '私域流量建设',
          '169': '数字资产化',
          '41': '一体化业务开发平台',
          '87': '关于我们',
          '-2': '新闻中心',
          '1':'企业简介',
          '168':'私域流量建设(解决方案)',
          '170':'数字资产化(解决方案)',
          '171':'产品案例',
          '2':'公司招聘'
        }
        tmp.articleTypeName = articleTypeNote[element.articleType];
        tmp.e54101c0 = articleTypeNote[element.articleType]
        tmp.isEdit = true;
        this.tbd_c7553564.push(tmp);
      });
    },
    // 从后台获取数据
    async refreshPage(pageNumFresh = 1) {
      let dicInput = {
        pnum: pageNumFresh,
        records: this.pageSize,
        ofield: "insertTime",
        sort: "",
      };
      dicInput.status = "0,2";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(
          dicInput
      );
      this.freshData(res, (pageNumFresh - 1) * this.pageSize + 1);
    },
    async articleDeleteClick (val) {
      console.log('111', val)
      let dicInput = {
        articleID: val.articleID
      }
      await DALINEWEB_ARTICLECENTER_V0X0X1_delArticle(dicInput)
      this.refreshPage()
    },
    async articleUpdateClick (val) {
      console.log(val,"va;");
      this.$router.push({path: 'EidtInformation', query: { articleID:val.articleID}})
      // ,articleContent:val.articleContent
    },
    //停用
    async ArticleStop(data) {
      let dicInputStop = { articleID: data.articleID };
      if (data.unStatus === "使用") {
        dicInputStop.status = 0;
      } else {
        dicInputStop.status = 2;
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle(dicInputStop);
      let msgTmp = ""
      if (res.retCode == 0) {
        let recommand = res.content;
        console.log(recommand,"QQQ");
        if (recommand.resCode == 0) {
          msgTmp = "操作失败";
        } else{
          msgTmp = "操作成功";
          data.unStatus = data.unStatus === '使用' ? '停用' : '使用'
          data.c331accc = data.c331accc === '使用' ? '停用' : '使用'
        }
       }
        this.$message(msgTmp);
    },
    //置顶
    async ArticleTopClick(data) {
      let dicInput = { articleID: data.articleID };
      if (data.fadfeca6 === true) {
        dicInput.top = 1;
      } else {
        dicInput.top = 0;
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle(dicInput);
      let msgTmp = "操作失败";
      if (res.retCode == 0) {
        let recommand = res.content;
        if (recommand.resCode == 0) {
          msgTmp = "操作成功";
        }
      }
      this.$message(msgTmp);
    },

    // 推荐到首页
    async RecommandClick(data) {
      let dicInput = { articleID: data.articleID };
      if (data.aea45ddb === true) {
        dicInput.preview = 1;
      } else {
        dicInput.preview = 0;
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle(dicInput);
      console.log("aaa",res);
      let msgTmp = "操作失败";
      if (res.retCode == 0) {
        let recommand = res.content;
        if (recommand.resCode == 0) {
          msgTmp = "操作成功";
        }
      }
      this.$message(msgTmp);
    },
    async moveUp(data){
      if (data == 0) {
        if (this.currentPage === 1) {
          this.$message("到顶啦！");
          return 1;
        }
      }
      else{
        let articleListTmp = this.tbd_c7553564;
        console.log(articleListTmp);
        const exchangeRes = await
        DALINEWEB_ARTICLECENTER_V0X0X1_exchangeOrderID(
           {
            articleID1: articleListTmp[data]["articleID"],
            articleID2: articleListTmp[data - 1]["articleID"],
          }
        )
        if (exchangeRes.retCode == 0) {
          const exchangeResData = exchangeRes.content;

          if (exchangeResData.resCode == 0) {
            const tmp = articleListTmp[data];
            articleListTmp[data] = articleListTmp[data - 1];
            articleListTmp[data - 1] = tmp;
            articleListTmp[data].a07e289f = data+1;
            articleListTmp[data - 1].a07e289f = data;
            this.$set(this.tbd_c7553564, data, articleListTmp[data]);
            this.$set(this.tbd_c7553564, data - 1, articleListTmp[data - 1]);
          }
        }
      }
    },
    async moveDown(data){
      console.log(data);
    },
    // 筛选分类
    async clicka6357e(pageNumFresh = 1){
    if(this.val_acad047c!=""){
      console.log("this",this.val_acad047c);
      let dicInput = {
        pnum: pageNumFresh,
        records: this.pageSize,
        ofield: "insertTime",
        sort: ""
      };
      dicInput.status = "0,2";
      dicInput.ArticleType = this.val_acad047c
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(
          dicInput
      );
      this.freshData(res, (pageNumFresh - 1) * this.pageSize + 1);
    }
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#e20a993e{
  padding-top:100px;
}
#e20a993e{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a6357c9e{
  width:120px;
  margin-top:-50px;
  margin-left:1255px;
}
#a6357c9e{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a6357c9e{
  color:#fff;
  font-size:13px;
  font-family:PingFang SC;
  border:transparent;
}
#c7553564{
  width:1322px;
  margin-top:15px;
  margin-left:163px;
}
#c7553564{
  display:inline-block;
  vertical-align: middle;
}
#ac56dd61{
  width:302px;
  margin-top:61px;
  padding-left:674px;
  padding-bottom:219px;
}
/*在此注释下方添加自定义样式*/
.ae22fdbf{
  width: 1502px;
  height: 30px;
  position: relative;
  margin-left: 72px;
  margin-right: 73px;
  margin-top: 30px;
  display: inline-block;
  vertical-align: middle;
}
#acad047c{
  width: 320px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-left:100px
}
</style>
